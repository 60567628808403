<template>
  <div class="page-recompenses">
    <div class="container">
      <h1>{{ $t("rewards.title")}}</h1>
      <div
        :class="raceClass(race)"
        v-for="race in getRacesToEvaluate" :key="race.id">
        <span  v-if="race.done.length === 0" class="invalid"></span>
        <router-link :to="{ name: 'reward', params: {race_id: race.id} }"
          :is="race.done.length === 0 ? 'span' : 'router-link'">
          <button >{{ getRaceLabel(getRaceById(race.id)) }}</button>
        </router-link>
      </div>

      <div class="actions">
        <button v-if="canValidate" class="action validate" @click="onValidate">
          {{ $t('rewards.go_to_summary') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dogMixin from '../common/dog.mixin';

export default {
  mixins: [dogMixin],
  name: 'rewards',
  computed: {
    ...mapGetters([
      'getRacesToEvaluate',
      'getRaceById',
      'currentLang',
      'canShowPreview',
    ]),
    canValidate() {
      return this.canShowPreview;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    raceClass(dogs) {
      // console.log(dogs);
      if (dogs.todo.length === 0) {
        return 'race completed';
      }
      if (dogs.done.length === 0) {
        return 'race todo';
      }
      return 'race partial';
    },
    onValidate() {
      this.$router.push({ name: 'validation' });
    },
  },
};
</script>

<style lang="scss">
  .page-recompenses {
    .race { margin-bottom: 1rem; }
    .completed button {
      background: url('~@/assets/check.png') no-repeat right #c8e6ef; color: #673445;
      background-size: contain;
      &:hover{cursor: pointer; background-color: #82cce3
      }
    }
    .partial button {
      background: #e9f7f8;color: #673445;
      background-size: contain;
      &:hover{cursor: pointer; background-color: #bbe9ef
      }
    }
    button{
      width: 100%; font-weight: 500; padding:1rem 0;
      text-transform: uppercase; line-height: .8rem; font-size: 1rem; border:1px #999 solid;
      &:hover{cursor: pointer; background-color: #B8B9BB}
    }
    .actions {
      margin: 3rem 0; display: flex; justify-content: space-between;
      .validate { margin:auto; width: 15rem }
    }
  }
</style>
